<template>
  <footer dark padless>
    <v-card flat tile class="white--text pa-4" color="#E0E0E0">
      <v-row no-gutters>
        <v-col cols="12" md="4" lg="4">
          <v-card class="pa-2" outlined color="#E0E0E0">
            <v-card-title class="text-6 font-weight-black"
              >Corporate Office:</v-card-title
            >

            <div v-for="item in Address" :key="item.title">
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon class="green lighten-1" dark>{{ item.icon }} </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    class="font-weight-black"
                    v-text="item.title"
                  ></v-list-item-title>

                  <v-list-item-subtitle
                    v-text="item.subtitle"
                  ></v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="item.sub2"
                    v-text="item.sub2"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider inset></v-divider>
            </div>
          </v-card>
        </v-col>
        <v-col order="12" cols="12" md="4" lg="4">
          <v-card class="pa-2 mx-auto" outlined tile color="#E0E0E0">
            <v-card-title class="font-weight-black"
              >Important Link</v-card-title
            >
            <v-row>
              <v-col
                v-for="(image, index) in imageUrls"
                :key="index"
                cols="4"
                sm="4"
              >
                <a :href="image.link" target="_blank">
                  <v-img
                    class="ma-1"
                    :src="image.url"
                    :alt="image.alt"
                    max-height="120"
                    width="100%"
                  ></v-img
                ></a>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col order="1" cols="12" md="4" lg="4">
          <v-card
            class="pa-2 text-lg-center font-weight-black h1"
            outlined
            tile
            flat
            color="#E0E0E0"
          >
            <v-card-title
              class="text-h5 font-weight-black justify-center"
              style="color: rgb(64, 19, 19)"
              >পুঁজিবাজারে বিনিয়োগ ঝুঁকিপূর্ণ। জেনে ও বুঝে বিনিয়োগ
              করুন।</v-card-title
            >
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-divider></v-divider>
    <div class="bg-secondary text-center py-3">
      <p>&copy; Developed by Nazifa IT Solutions.</p>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      selectedItem: 1,
      Address: [
        {
          icon: "mdi-map-marker",
          subtitle: "Shadharan Bima Tower(7th Floor)37/A, ",
          sub2: "Dilkusha C/A ,Dhaka-1000",
          title: "Address",
        },
        {
          icon: "mdi-phone",
          subtitle: "0247112923",
          title: "Phone",
        },
        {
          icon: "mdi-fax",
          subtitle: "88-02-47118073",
          title: "Fax",
        },
        {
          icon: "mdi-email",
          subtitle: "info@ril.com.bd",
          title: "Email",
        },
      ],
      imageUrls: [
        {
          url: require("@/assets/Footer/DSE-Sept-620130905203930.png"),
          alt: "Image 1",
          width: 300,
          height: 200,
          link: "http://www.dsebd.org/",
        },
        {
          url: require("@/assets/Footer/bangladesh-bank-logo-D3A2278B52-seeklogo.com_.gif-150x150.png"),
          alt: "Image 2",
          width: 300,
          height: 200,
          link: "https://www.bb.org.bd/",
        },
        {
          url: require("@/assets/Footer/cse-logo-s1.png"),
          alt: "Image 3",
          width: 300,
          height: 200,
          link: "http://www.cse.com.bd/",
        },
        {
          url: require("@/assets/Footer/Icb-logo.png"),
          alt: "Image 3",
          width: 100,
          height: 200,
          link: "http://www.icb.gov.bd/",
        },
        {
          url: require("@/assets/Footer/secu.png"),
          alt: "Image 3",
          width: 300,
          height: 200,
          link: "http://www.secbd.org/",
        },
        {
          url: require("@/assets/Footer/Rbllogo.png"),
          alt: "Rbllogo",
          width: 300,
          height: 200,
          link: "https://www.rupalibank.com.bd/",
        },
        // Add more image URLs as needed
      ],
    };
  },
  methods: {
    redirectToLink(link) {
      window.location.href = link;
    },
  },
  // Component logic goes here
};
</script>

<style scoped>
/* Add your custom styling here */
</style>
